
















import { Component, Prop, Vue } from 'vue-property-decorator'
import AIScriptCard from './AIScriptCard.vue'

@Component({
  components: { AIScriptCard },
})
export default class VoiceProjectList extends Vue {
  @Prop() aiScriptList: any
  @Prop() template!: any

  isDeleting!: boolean
  isScriptLoading!: boolean

  get status() {
    if (this.template.status == 'STARTED')
      return `<div class="light-yellow"><i class="material-icons light-gray f4 v-mid pa1 light-yellow "> schedule </i>Training in progress</div>`
    else if (this.template.status == 'FINISHED')
      return `<div class="light-green"><i class="material-icons light-gray f4 v-mid pa1 light-green  "> task_alt </i>Ready to Use</div>`
    else
      return `<div class="light-blue"><i class="material-icons light-gray f4 v-mid pa1 light-blue"> start </i>Start training to clone Voice</div>`
  }

  get BtnText() {
    if (!this.template.status) return 'Start Training'
    else if (this.template.status == 'FINISHED') return 'Use Voice'
  }

  editScript() {
    this.$router.push(`/ai/${this.template.enum}`)
  }
}
