













import { Component, Prop, Vue } from 'vue-property-decorator'
import AIScriptCard from './AIScriptCard.vue'

@Component({
  components: { AIScriptCard },
})
export default class AITemplateList extends Vue {
  @Prop() aiScriptList: any

  handleEditTemplate() {
    this.$router.push(`/ai/template/new?enum=${this.template.enum}&name=${this.template.name}`)
  }
  @Prop() template!: any

  isDeleting!: boolean
  isScriptLoading!: boolean

  editScript() {
    this.$router.push(`/ai/${this.template.enum}`)
  }
}
