var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.aiScriptList.length
      ? _c(
          "div",
          [
            _vm._m(0),
            _vm._l(_vm.aiScriptList, function (script) {
              return _c("AIScriptCard", {
                key: script.id,
                attrs: { script: script },
              })
            }),
          ],
          2
        )
      : _c("div", [_vm._m(1)]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "flex flex-wrap justify-between b ph1 pv3 mt4 link light-gray bg-adori-light-gray bb b--adori-gray br2 relative",
      },
      [
        _c("div", { staticClass: "w-20 flex justify-center" }, [
          _vm._v("Title"),
        ]),
        _c("div", { staticClass: "w-20 flex justify-center" }, [
          _vm._v("Template"),
        ]),
        _c("div", { staticClass: "w-10 flex justify-center" }, [
          _vm._v("Created On"),
        ]),
        _c("div", { staticClass: "w-10 flex justify-center" }, [
          _vm._v("Action"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex flex-column items-center mt5" }, [
      _c("img", {
        staticClass: "mt5",
        attrs: {
          src: require("@/assets/adori-ai.svg"),
          alt: "cover",
          width: "300",
        },
      }),
      _c("div", { staticClass: "white f5 mt3" }, [
        _vm._v("You have not created any new scripts yet!"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }