























import { Component, Prop, Vue } from 'vue-property-decorator'
import AIScriptCard from './AIScriptCard.vue'

@Component({
  components: { AIScriptCard },
})
export default class AIScriptList extends Vue {
  @Prop() aiScriptList: any
}
