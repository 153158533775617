var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "relative ba ma2 b--adori-gray br3 pa3 white mh-150 bg-adori-medium-gray noselect",
    },
    [
      _c("div", { staticClass: "word-wrap pt2 b" }, [
        _vm._v(_vm._s(_vm.template.name)),
      ]),
      _c("div", { staticClass: "flex" }, [
        _c("span", { staticClass: "badge1 mr2" }, [
          _vm._v(_vm._s(_vm.template.labels.gender)),
        ]),
        _c("span", { staticClass: "badge2" }, [
          _vm._v(_vm._s(_vm.template.labels.age)),
        ]),
      ]),
      _c("div", {
        staticClass: "f6 gray pt3 mb2",
        domProps: { innerHTML: _vm._s(_vm.status) },
      }),
      _vm._m(0),
      _vm.template.status != "STARTED"
        ? _c("BaseButtonRed", { attrs: { text: _vm.BtnText } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "absolute right-0 top-1 o-70 glow flex flex-column pointer",
      },
      [
        _c(
          "i",
          { staticClass: "material-icons light-gray f4 v-mid pa1 dark-red" },
          [_vm._v(" delete ")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }