var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "relative ba ma2 b--adori-gray br3 pa3 white mh-150 pointer bg-adori-medium-gray grow noselect",
      on: { click: _vm.handleEditTemplate },
    },
    [
      _c("div", { staticClass: "word-wrap pt2 b" }, [
        _vm._v(_vm._s(_vm.template.name)),
      ]),
      _c("div", { staticClass: "f6 gray pt3" }, [
        _vm._v(_vm._s(_vm.template.description)),
      ]),
      _vm._m(0),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "absolute right-0 bottom-0 flex flex-column" },
      [
        _c("i", { staticClass: "material-icons light-gray f4 v-mid pa1" }, [
          _vm._v(" south_east "),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }