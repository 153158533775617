var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TheMainPage",
    [
      _c("TheHeader"),
      _c(
        "div",
        { staticClass: "flex flex-wrap" },
        [
          _c("TheSideNavigation"),
          _c("div", { staticClass: "limiter-no-padding scrollbar" }, [
            _c(
              "div",
              { staticClass: "mt4 mb2" },
              [
                _c("BaseButtonTab", {
                  staticClass: "ml3",
                  attrs: {
                    text: "Templates",
                    active: _vm.currentTab === "TEMPLATES",
                    onClick: function () {
                      _vm.currentTab = "TEMPLATES"
                      _vm.$router.push("/ai/templates")
                    },
                  },
                }),
                _c("BaseButtonTab", {
                  staticClass: "ml4",
                  attrs: {
                    text: "Bookmarks",
                    active: _vm.currentTab === "BOOKMARKS",
                    onClick: function () {
                      _vm.currentTab = "BOOKMARKS"
                      _vm.$router.push("/ai/bookmarks")
                    },
                  },
                }),
                _c("BaseButtonTab", {
                  staticClass: "ml4",
                  attrs: {
                    text: "AI Voice Clone",
                    active: _vm.currentTab === "CLONE",
                    onClick: function () {
                      _vm.currentTab = "CLONE"
                      _vm.$router.push("/ai/clone")
                    },
                  },
                }),
              ],
              1
            ),
            _vm.isScriptLoading || _vm.isTemplateLoading
              ? _c(
                  "div",
                  { staticClass: "flex flex-wrap ml3 mt3" },
                  [
                    _vm._l(new Array(50), function (_, index) {
                      return _vm.currentTab === "TEMPLATES"
                        ? _c("div", {
                            key: index,
                            staticClass:
                              "w-100 bb bw1 b--adori-gray bg-adori-light-gray flex justify-center items-center mr3 mb3 br2 skeleton custom-size-template",
                          })
                        : _vm._e()
                    }),
                    _vm._l(new Array(50), function (_, index) {
                      return _vm.currentTab === "BOOKMARKS"
                        ? _c("div", {
                            key: index,
                            staticClass:
                              "w-100 bb bw1 b--adori-gray bg-adori-light-gray mr3 mb2 br2 skeleton custom-size-bookmark",
                          })
                        : _vm._e()
                    }),
                  ],
                  2
                )
              : _c("div", [
                  _vm.currentTab === "TEMPLATES"
                    ? _c(
                        "div",
                        { staticClass: "temp" },
                        _vm._l(_vm.templateList, function (template) {
                          return _c("AITemplateList", {
                            key: template.enum,
                            attrs: { template: template },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm.currentTab === "BOOKMARKS"
                    ? _c(
                        "div",
                        [
                          _c("AIScriptList", {
                            attrs: { aiScriptList: _vm.list },
                          }),
                          _vm.list.length
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex justify-end pv3 ph4 bt bg-adori-light-gray b--adori-gray bw1",
                                },
                                [
                                  _c("BaseButtonRed", {
                                    staticClass: "mr3 page",
                                    attrs: {
                                      text: "Prev",
                                      onClick: _vm.prev,
                                      disabled: _vm.pageNo === 1,
                                    },
                                  }),
                                  _c("input", {
                                    staticClass:
                                      "bg-adori-light-gray adori-gray br2 w-38",
                                    attrs: { type: "text" },
                                    domProps: { value: _vm.pageNo },
                                    on: {
                                      input: function ($event) {
                                        _vm.OnPageNumberChanged(
                                          parseInt($event.target.value)
                                        )
                                      },
                                    },
                                  }),
                                  _c("span", { staticClass: "pa2 silver" }, [
                                    _vm._v("/ " + _vm._s(_vm.pageCount)),
                                  ]),
                                  _c("BaseButtonRed", {
                                    staticClass: "ml2 page",
                                    attrs: {
                                      text: "Next",
                                      onClick: _vm.next,
                                      disabled: _vm.pageNo === _vm.pageCount,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentTab === "CLONE"
                    ? _c("div", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex flex-column mt4 pb4 justify-center items-center silver pa3 tc bg-adori-light-gray ba b--adori-gray br2 br-bottom",
                          },
                          [
                            _c(
                              "i",
                              {
                                staticClass:
                                  "material-icons white f2 v-mid mb1 gold",
                              },
                              [_vm._v(" electric_bolt ")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "light-gray ph3 pv2 f4 db b-color",
                              },
                              [_vm._v("Instant Voice Cloning")]
                            ),
                            _c("div", { staticClass: "w-40" }, [
                              _vm._v(
                                "\n              Clone a voice from a clean sample recording. Samples should contain 1 speaker and not contain background\n              noise.\n            "
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "light-gray ph3 pv2 f4 db b-color",
                              },
                              [
                                _c("BaseButtonRed", {
                                  attrs: {
                                    onClick: _vm.handleNew,
                                    text: "+New Project",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "f3 light-gray mt4 mb2" }, [
                          _vm._v("Projects"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "temp" },
                          _vm._l(_vm.projectList, function (project) {
                            return _c("VoiceProjectList", {
                              key: project.name,
                              attrs: { template: project },
                            })
                          }),
                          1
                        ),
                      ])
                    : _vm._e(),
                ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }