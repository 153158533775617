var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "flex flex-wrap justify-between ph1 pv3 link light-gray bb b--adori-gray mb relative",
      },
      [
        _c(
          "div",
          { staticClass: "w-20 flex justify-center items-center pointer tc" },
          [_vm._v("\n      " + _vm._s(_vm.script.name) + "\n    ")]
        ),
        _c("div", { staticClass: "w-20 flex justify-center items-center" }, [
          _vm._v(_vm._s(_vm.templateList[_vm.script.template].name)),
        ]),
        _c("div", { staticClass: "w-15 flex justify-center items-center" }, [
          _c("i", { staticClass: "f5 material-icons red inline mr2" }),
          _vm._v("\n      " + _vm._s(_vm.formatDate) + "\n    "),
        ]),
        _c("div", { staticClass: "w-10 flex justify-center items-center" }, [
          _c(
            "i",
            {
              staticClass: "material-icons light-gray f4 v-mid pa1 pointer",
              on: { click: _vm.editScript },
            },
            [_vm._v(" edit ")]
          ),
          !_vm.isDeleting
            ? _c(
                "i",
                {
                  staticClass: "material-icons light-gray f4 v-mid pa1 pointer",
                  on: {
                    click: function ($event) {
                      return _vm.deleteScript(_vm.script.id)
                    },
                  },
                },
                [_vm._v("\n        delete\n      ")]
              )
            : _c("div", { staticClass: "loader loader-inline v-mid pa1" }),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }